import React from 'react';

function Footer(props) {
    return (
        <div className='footer-container'>
            <p>Created with Poke API</p>
        </div>
    );
}

export default Footer;